import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@tsc/component-library/lib/components";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import { MANAGEMENT_TYPES } from "enums/rbacManagementTypes";
import { filter, includes, pick, sortBy } from "lodash";

import {
  useCreateOrganizationRoleMutation,
  useDeleteOrganizationRoleMutation,
  useGetOrganizationRolesQuery,
  useUpdateOrganizationRoleMutation,
} from "api/organizationRoles";
import { useGetOrganizationsQuery } from "api/organizations";
import {
  useGetOrganizationUsersQuery,
  useUpdateOrganizationUserMutation,
} from "api/organizationUsers";
import { useGetPermissionsQuery } from "api/permissions";
import {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useGetRolesQuery,
  useUpdateRoleMutation,
} from "api/roles";
import { useGetUsersQuery, useUpdateUserMutation } from "api/users";
import OrganizationRoles from "components/AccessControl/OrganizationRoles";
import ResourceUsers from "components/AccessControl/ResourceUsers";
import { LabeledDropdown } from "components/Forms/LabeledDropdown";
import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import { selectPermissions } from "features/authentication/authenticationSlice";
import { hasPermissionToken } from "utilities/data";
import {
  ADMIN_ORGANIZATION_ID,
  checkIfSuperAdminOrg,
  preparePermissionPayload,
} from "utilities/permission";
import { prepareUserPayload } from "utilities/user";

const ROLE_LABELS = {
  [OBJECT_TYPE.MANAGEMENT]: "Management",
  [OBJECT_TYPE.ORGANIZATION]: "Organization",
  [OBJECT_TYPE.PROJECT]: "Project",
};

function prepareRolePayload(role, objectType, isCustomer) {
  return {
    ...role,
    objectType: isCustomer ? objectType : undefined,
    global: isCustomer ? undefined : role.global,
  };
}

const getRoleLevelOptions = (managementType) => {
  let options = {};
  switch (managementType) {
    case MANAGEMENT_TYPES.CUSTOMER:
      options = pick(ROLE_LABELS, ["organization", "project"]);
      break;
    case MANAGEMENT_TYPES.ORGANIZATION_MANAGEMENT:
    case MANAGEMENT_TYPES.GLOBAL_MANAGEMENT:
      options = pick(ROLE_LABELS, ["management"]);
      break;
    default:
      options = {};
  }

  return Object.keys(options);
};

const AccessControl = () => {
  const userPermissions = useSelector(selectPermissions);
  const hasAdministrationManagePermission = hasPermissionToken(
    userPermissions,
    OBJECT_TYPE.MANAGEMENT,
    OBJECT_NAME.MANAGE
  );

  const { currentData: { data: organizations = [] } = {} } =
    useGetOrganizationsQuery();

  const [createAdminRole] = useCreateRoleMutation();
  const [updateAdminRole] = useUpdateRoleMutation();
  const [deleteAdminRole] = useDeleteRoleMutation();

  const [createOrgRole] = useCreateOrganizationRoleMutation();
  const [updateOrgRole] = useUpdateOrganizationRoleMutation();
  const [deleteOrgRole] = useDeleteOrganizationRoleMutation();

  const [updateAdminUser] = useUpdateUserMutation();
  const [updateOrgUser] = useUpdateOrganizationUserMutation();

  const [selectedManagement, setSelectedManagement] = useState(
    MANAGEMENT_TYPES.CUSTOMER
  );
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [roleType, setRoleType] = useState(OBJECT_TYPE.ORGANIZATION);

  const { currentData: { data: adminRoles = [] } = {} } = useGetRolesQuery(
    { organizationId: selectedOrganizationId },
    {
      skip:
        !selectedOrganizationId ||
        selectedManagement === MANAGEMENT_TYPES.CUSTOMER,
    }
  );
  const { currentData: { data: organizationRoles = [] } = {} } =
    useGetOrganizationRolesQuery(
      { organizationId: selectedOrganizationId },
      {
        skip:
          !selectedOrganizationId ||
          selectedManagement !== MANAGEMENT_TYPES.CUSTOMER,
      }
    );

  const filteredRoles =
    selectedManagement === MANAGEMENT_TYPES.CUSTOMER
      ? organizationRoles.filter((role) => role.objectType === roleType)
      : selectedManagement === MANAGEMENT_TYPES.GLOBAL_MANAGEMENT
      ? adminRoles.filter((role) => role.global)
      : adminRoles.filter((role) => !role.global);

  const { currentData: { data: permissions = [] } = {} } =
    useGetPermissionsQuery();

  const filteredPermissions =
    selectedManagement === MANAGEMENT_TYPES.CUSTOMER
      ? filter(permissions, ({ level }) => level === roleType)
      : permissions;

  const { adminUsers } = useGetUsersQuery(
    {
      objectType: roleType,
      objectId: checkIfSuperAdminOrg(selectedOrganizationId)
        ? "*"
        : selectedOrganizationId,
    },
    {
      skip:
        !selectedOrganizationId ||
        selectedManagement === MANAGEMENT_TYPES.CUSTOMER,
      selectFromResult: (result) => ({
        adminUsers: sortBy(result.currentData?.data ?? [], "displayName"),
      }),
    }
  );
  const { orgUsers } = useGetOrganizationUsersQuery(
    {
      organizationId: selectedOrganizationId,
      objectType: "organization",
      objectId: selectedOrganizationId,
    },
    {
      skip:
        !selectedOrganizationId ||
        selectedManagement !== MANAGEMENT_TYPES.CUSTOMER,
      selectFromResult: (result) => ({
        orgUsers: sortBy(result.currentData?.data ?? [], "displayName"),
      }),
    }
  );
  const users =
    selectedManagement === MANAGEMENT_TYPES.CUSTOMER ? orgUsers : adminUsers;

  const handleCreateRole = (name) => {
    const isCustomer = selectedManagement === MANAGEMENT_TYPES.CUSTOMER;
    const createRole =
      selectedManagement === MANAGEMENT_TYPES.CUSTOMER
        ? createOrgRole
        : createAdminRole;
    const organizationId = checkIfSuperAdminOrg(selectedOrganizationId)
      ? null
      : selectedOrganizationId;
    const isGlobal = selectedOrganizationId === ADMIN_ORGANIZATION_ID;
    const role = prepareRolePayload(
      {
        name,
        organizationId,
        permissions: [],
        global: isGlobal,
      },
      roleType,
      isCustomer
    );

    createRole({
      organizationId,
      role,
    });
  };

  const handleUpdateRole = (role) => {
    const isCustomer = selectedManagement === MANAGEMENT_TYPES.CUSTOMER;
    const updateRole = isCustomer ? updateOrgRole : updateAdminRole;
    const organizationId = checkIfSuperAdminOrg(selectedOrganizationId)
      ? null
      : selectedOrganizationId;
    const isGlobal = selectedOrganizationId === ADMIN_ORGANIZATION_ID;
    const updatedRole = prepareRolePayload(
      {
        ...pick(role, ["id", "name", "organizationId", "version"]),
        permissions: preparePermissionPayload(role),
        global: isGlobal,
      },
      roleType,
      isCustomer
    );

    updateRole({
      organizationId,
      roleId: role.id,
      role: updatedRole,
    });
  };

  const handleDeleteRole = (roleId) => {
    const deleteRole =
      selectedManagement === MANAGEMENT_TYPES.CUSTOMER
        ? deleteOrgRole
        : deleteAdminRole;
    deleteRole({ organizationId: selectedOrganizationId, roleId });
  };

  const handleCreateUser = ({ displayName, email, userRoles }) => {
    const isCustomer = selectedManagement === MANAGEMENT_TYPES.CUSTOMER;
    const isGlobalManagement =
      selectedManagement === MANAGEMENT_TYPES.GLOBAL_MANAGEMENT;
    const objectId = isGlobalManagement ? "*" : selectedOrganizationId;
    const objectType = roleType;
    const updateUser = isCustomer ? updateOrgUser : updateAdminUser;
    const roleKey = isCustomer ? "userRoles" : "userManagementRoles";
    const roleIdKey = isCustomer ? "roleId" : "managementRoleId";

    updateUser({
      organizationId: selectedOrganizationId,
      objectType,
      objectId,
      userId: email,
      user: {
        displayName,
        email,
        [roleKey]: userRoles.map((role) => ({
          [roleIdKey]: role,
          objectId,
        })),
      },
    });
  };

  const handleUpdateUser = (user) => {
    const isCustomer = selectedManagement === MANAGEMENT_TYPES.CUSTOMER;
    const isGlobalManagement =
      selectedManagement === MANAGEMENT_TYPES.GLOBAL_MANAGEMENT;
    const objectId = isGlobalManagement ? "*" : selectedOrganizationId;
    const objectType = roleType;
    const updateUser = isCustomer ? updateOrgUser : updateAdminUser;
    updateUser({
      organizationId: selectedOrganizationId,
      objectType,
      objectId,
      userId: user.email,
      user: prepareUserPayload(user, objectId, isCustomer),
    });
  };

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const TABS = [
    {
      label: "Customer Roles",
      component: (
        <CustomerPermissions
          organizationId={selectedOrganizationId}
          organizations={organizations}
          organizationRoles={organizationRoles}
          filteredRoles={filteredRoles}
          filteredPermissions={filteredPermissions}
          handleCreateRole={handleCreateRole}
          handleUpdateRole={handleUpdateRole}
          handleDeleteRole={handleDeleteRole}
          users={users}
          handleUpdateUser={handleUpdateUser}
          handleCreateUser={handleCreateUser}
          roleType={roleType}
          setSelecteOrganizationId={setSelectedOrganizationId}
          setRoleType={setRoleType}
        />
      ),
    },
    {
      label: "Organization Management Roles",
      component: (
        <OrganizationManagementPermissions
          organizationId={selectedOrganizationId}
          organizations={organizations}
          organizationRoles={organizationRoles}
          filteredRoles={filteredRoles}
          filteredPermissions={filteredPermissions}
          handleCreateRole={handleCreateRole}
          handleUpdateRole={handleUpdateRole}
          handleDeleteRole={handleDeleteRole}
          users={users}
          handleUpdateUser={handleUpdateUser}
          handleCreateUser={handleCreateUser}
          roleType={roleType}
          setSelecteOrganizationId={setSelectedOrganizationId}
          setRoleType={setRoleType}
        />
      ),
    },
    {
      label: "Global Management Roles",
      disabled: !hasAdministrationManagePermission,
      component: (
        <GlobalManagementPermissions
          organizationRoles={organizationRoles}
          filteredRoles={filteredRoles}
          filteredPermissions={filteredPermissions}
          handleCreateRole={handleCreateRole}
          handleUpdateRole={handleUpdateRole}
          handleDeleteRole={handleDeleteRole}
          users={users}
          handleUpdateUser={handleUpdateUser}
          handleCreateUser={handleCreateUser}
        />
      ),
    },
  ];

  return (
    <Page>
      <SubPage>
        <Tabs
          value={currentTabIndex}
          onChange={(_, index) => {
            setCurrentTabIndex(index);
            if (index === 0) {
              setSelectedManagement(MANAGEMENT_TYPES.CUSTOMER);
              setRoleType(OBJECT_TYPE.ORGANIZATION);
              setSelectedOrganizationId(null);
            } else if (index === 1) {
              setSelectedManagement(MANAGEMENT_TYPES.ORGANIZATION_MANAGEMENT);
              setRoleType(OBJECT_TYPE.MANAGEMENT);
              setSelectedOrganizationId(null);
            } else if (index === 2) {
              setSelectedManagement(MANAGEMENT_TYPES.GLOBAL_MANAGEMENT);
              setRoleType(OBJECT_TYPE.MANAGEMENT);
              setSelectedOrganizationId(ADMIN_ORGANIZATION_ID);
            }
          }}
          aria-label="Main tabs"
        >
          {TABS.map((tab, index) => (
            <Tab
              key={tab.label}
              value={index}
              label={tab.label}
              id={tab.id}
              aria-controls={tab.ariaControls}
              disabled={tab.disabled}
            />
          ))}
        </Tabs>
        <Divider />
        <Box my={2}>{TABS[currentTabIndex].component}</Box>
      </SubPage>
    </Page>
  );
};

export default AccessControl;

const CustomerPermissions = ({
  organizationId,
  organizations,
  roleType,
  organizationRoles,
  filteredRoles,
  filteredPermissions,
  handleCreateRole,
  handleUpdateRole,
  handleDeleteRole,
  users,
  handleUpdateUser,
  handleCreateUser,
  setSelecteOrganizationId,
  setRoleType,
}) => {
  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems="baseline">
        <Box width="250px">
          <Typography variant="subtitle1" fontWeight="500">
            Select Organization
          </Typography>
        </Box>
        <LabeledDropdown
          label="Organizations"
          data={organizations}
          value={organizationId ?? ""}
          getOptionLabel={(organization) => organization?.name}
          onChange={(e) => {
            setSelecteOrganizationId(e.target.value);
          }}
        />
      </Stack>
      <Divider />
      {organizationId && (
        <Stack direction="row" gap={1} alignItems="baseline">
          <Box width="250px">
            <Typography variant="subtitle1" fontWeight="500">
              Select Role Level
            </Typography>
          </Box>
          <LabeledDropdown
            label="Role Levels"
            data={getRoleLevelOptions(MANAGEMENT_TYPES.CUSTOMER)}
            getValue={(value) => value}
            value={roleType ?? ""}
            getId={(value) => value}
            getOptionLabel={(value) => ROLE_LABELS[value]}
            onChange={(e) => {
              setRoleType(e.target.value);
            }}
          />
        </Stack>
      )}
      {organizationId && organizationRoles && (
        <>
          <Divider />
          <OrganizationRoles
            securitySettings={filteredRoles}
            permissions={filteredPermissions}
            onCreateRole={handleCreateRole}
            onUpdateRole={handleUpdateRole}
            onDeleteRole={handleDeleteRole}
          />
          <Divider />
          {includes(
            [OBJECT_TYPE.MANAGEMENT, OBJECT_TYPE.ORGANIZATION],
            roleType
          ) && (
            <ResourceUsers
              users={users}
              roles={filteredRoles}
              organizationId={organizationId}
              objectType={roleType}
              managementType={MANAGEMENT_TYPES.CUSTOMER}
              onUpdateUser={handleUpdateUser}
              onCreateUser={handleCreateUser}
            />
          )}
        </>
      )}
    </Stack>
  );
};

const OrganizationManagementPermissions = ({
  organizationId,
  organizations,
  roleType,
  organizationRoles,
  filteredRoles,
  filteredPermissions,
  handleCreateRole,
  handleUpdateRole,
  handleDeleteRole,
  users,
  handleUpdateUser,
  handleCreateUser,
  setSelecteOrganizationId,
  setRoleType,
}) => {
  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems="baseline">
        <Box width="250px">
          <Typography variant="subtitle1" fontWeight="500">
            Select Organization
          </Typography>
        </Box>
        <LabeledDropdown
          label="Organizations"
          data={organizations}
          value={organizationId ?? ""}
          getOptionLabel={(organization) => organization?.name}
          onChange={(e) => {
            setSelecteOrganizationId(e.target.value);
          }}
        />
      </Stack>
      {organizationId && organizationRoles && (
        <>
          <Divider />
          <OrganizationRoles
            securitySettings={filteredRoles}
            permissions={filteredPermissions}
            onCreateRole={handleCreateRole}
            onUpdateRole={handleUpdateRole}
            onDeleteRole={handleDeleteRole}
          />
          <Divider />
          <ResourceUsers
            users={users}
            roles={filteredRoles}
            organizationId={organizationId}
            objectType={OBJECT_TYPE.MANAGEMENT}
            managementType={MANAGEMENT_TYPES.ORGANIZATION_MANAGEMENT}
            onUpdateUser={handleUpdateUser}
            onCreateUser={handleCreateUser}
          />
        </>
      )}
    </Stack>
  );
};

const GlobalManagementPermissions = ({
  organizationRoles,
  filteredRoles,
  filteredPermissions,
  handleCreateRole,
  handleUpdateRole,
  handleDeleteRole,
  users,
  handleUpdateUser,
  handleCreateUser,
}) => {
  return (
    <Stack gap={1}>
      {organizationRoles && (
        <>
          <OrganizationRoles
            securitySettings={filteredRoles}
            permissions={filteredPermissions}
            onCreateRole={handleCreateRole}
            onUpdateRole={handleUpdateRole}
            onDeleteRole={handleDeleteRole}
          />
          <Divider />
          <ResourceUsers
            users={users}
            roles={filteredRoles}
            organizationId={ADMIN_ORGANIZATION_ID}
            objectType={OBJECT_TYPE.MANAGEMENT}
            managementType={MANAGEMENT_TYPES.GLOBAL_MANAGEMENT}
            onUpdateUser={handleUpdateUser}
            onCreateUser={handleCreateUser}
          />
        </>
      )}
    </Stack>
  );
};
