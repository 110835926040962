import {
  ATIUM_SOURCE_IDENTIFIER,
  STAKEHOLDER_SOURCE,
  STAKEHOLDER_SOURCE_LOGO,
  STAKEHOLDER_SOURCE_PRIORITY,
} from "enums/stakeholderSource";
import { cloneDeep, isEmpty, upperFirst } from "lodash";

const STAKEHOLDER_SOURCE_URL_BUILDERS = {
  [STAKEHOLDER_SOURCE.WIKIDATA]: (_, sourceId) =>
    `https://www.wikidata.org/wiki/${sourceId}`,
  [STAKEHOLDER_SOURCE.LITTLESIS]: (type, sourceId) =>
    `https://littlesis.org/${type === "person" ? "person" : "org"}/${sourceId}`,
  [STAKEHOLDER_SOURCE.LINKED_IN]: (_, sourceId) =>
    `https://www.linkedin.com/${sourceId}`,
};

export const attributeSorter = (sa1, sa2) =>
  (STAKEHOLDER_SOURCE_PRIORITY[sa1.source] ?? Number.MAX_SAFE_INTEGER) -
  (STAKEHOLDER_SOURCE_PRIORITY[sa2.source] ?? Number.MAX_SAFE_INTEGER);

export const getStakeholderAttribute = (stakeholder, attributeName) => {
  const stakeholderAttributes = cloneDeep(
    stakeholder?.stakeholderAttributes ?? []
  );
  const nonNullAttribute =
    stakeholderAttributes
      .sort(attributeSorter)
      .find((attrs) =>
        typeof attrs[attributeName] === "object"
          ? !isEmpty(attrs[attributeName])
          : !!attrs[attributeName]
      ) ?? {};
  return nonNullAttribute[attributeName];
};

export const getStakeholderSourceTag = (stakeholderAttribute) => {
  if (!stakeholderAttribute?.source) {
    return null;
  }

  let stakeholderSource = stakeholderAttribute.source;

  if (stakeholderSource === STAKEHOLDER_SOURCE.ATIUM) {
    stakeholderSource = ATIUM_SOURCE_IDENTIFIER;
  }

  return stakeholderAttribute.sourceId
    ? `${upperFirst(stakeholderSource)} (${stakeholderAttribute.sourceId})`
    : `${upperFirst(stakeholderSource)}`;
};

export const getStakeholderSourceTags = (stakeholderAttributes) => {
  return [...stakeholderAttributes].map((sa) => getStakeholderSourceTag(sa));
};

export const getStakeholderSourceLogo = (stakeholderAttribute) => {
  if (!stakeholderAttribute?.source) {
    return null;
  }
  return STAKEHOLDER_SOURCE_LOGO[stakeholderAttribute.source];
};

export const getStakeholderSourceUrl = (stakeholder, stakeholderAttribute) => {
  if (!stakeholderAttribute?.source) {
    return null;
  }
  const type = stakeholder?.type;
  const { source, sourceId } = stakeholderAttribute;
  return STAKEHOLDER_SOURCE_URL_BUILDERS[source]?.(type, sourceId);
};
