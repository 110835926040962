import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  IssueCard,
  PaginatedList,
  Stack,
} from "@tsc/component-library/lib/components";
import { useSearchParams } from "@tsc/component-library/lib/hooks";
import { TAGGING_LOGIC } from "enums/issueTaggingLogic";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import { filter, keyBy, some } from "lodash";

import { useGetIssuesQuery } from "api/issues";
import { useDeleteLibraryIssueMutation } from "api/libraryIssue";
import { useDeleteOrganizationIssueMutation } from "api/organizationIssue";
import { useGetOrganizationsQuery } from "api/organizations";
import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import { selectPermissions } from "features/authentication/authenticationSlice";
import RestrictedAccessTooltip from "features/authentication/RestrictedAccessTooltip/RestrictedAccessTooltip";
import DeleteIssueDialog from "features/issues/DeleteIssueDialog/DeleteIssueDialog";
import IssueFilter from "features/issues/IssueFilter/IssueFilter";
import { hasPermissionToken } from "utilities/data";

const Issues = () => {
  const navigate = useNavigate();
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [isDeleteIssueDialogOpen, setIsDeleteIssueDialogOpen] = useState(false);
  const [searchParams, query, setSearchParams] = useSearchParams();
  const { page, pageSize } = searchParams;
  const {
    data: { data: issues = [], totalCount = 0 } = {},
    isFetching: isFetchingIssues,
  } = useGetIssuesQuery({ query });
  const [deleteLibraryIssue, { isLoading: isDeletingLibraryIssue }] =
    useDeleteLibraryIssueMutation();
  const [deleteOrganizationIssue, { isLoading: isDeletingOrganizationIssue }] =
    useDeleteOrganizationIssueMutation();

  const {
    data: { data: organizations = [] } = {},
    isFetching: isFetchingOrganizations,
  } = useGetOrganizationsQuery({});
  const organizationsDictionary = keyBy(organizations, "id");

  const isDeleting = isDeletingLibraryIssue || isDeletingOrganizationIssue;
  const isFetching = isFetchingIssues || isFetchingOrganizations;

  const permissions = useSelector(selectPermissions);
  const hasCreateLibraryIssuePermission = hasPermissionToken(
    permissions,
    OBJECT_TYPE.LIBRARY_ISSUE,
    OBJECT_NAME.CREATE
  );
  const hasUpdateLibraryIssuePermission = hasPermissionToken(
    permissions,
    OBJECT_TYPE.LIBRARY_ISSUE,
    OBJECT_NAME.UPDATE
  );
  const hasDeleteLibraryIssuePermission = hasPermissionToken(
    permissions,
    OBJECT_TYPE.LIBRARY_ISSUE,
    OBJECT_NAME.DELETE
  );

  const hasCreateOrganizationIssuePermissions = some(
    organizations,
    ({ permissions }) =>
      hasPermissionToken(
        permissions,
        OBJECT_TYPE.ORGANIZATION_ISSUE,
        OBJECT_NAME.CREATE
      )
  );
  const editOrganizationIssuePermissions = new Set(
    filter(organizations, ({ permissions }) =>
      hasPermissionToken(
        permissions,
        OBJECT_TYPE.ORGANIZATION_ISSUE,
        OBJECT_NAME.UPDATE
      )
    ).map((organization) => organization.id.toString())
  );
  const deleteOrganizationIssuePermissions = new Set(
    filter(organizations, ({ permissions }) =>
      hasPermissionToken(
        permissions,
        OBJECT_TYPE.ORGANIZATION_ISSUE,
        OBJECT_NAME.DELETE
      )
    ).map((organization) => organization.id.toString())
  );

  const handleSearch = (value) => {
    setSearchParams({ ...searchParams, ...value });
  };

  const handleDeleteIssue = async ({ id: issueId, organizationId }) => {
    try {
      const deleteIssue = organizationId
        ? deleteOrganizationIssue
        : deleteLibraryIssue;
      await deleteIssue({ issueId, organizationId })?.unwrap();
      closeDeleteIssueDialog();
    } catch (error) {}
  };

  const openDeleteIssueDialog = (issue) => {
    setSelectedIssue(issue);
    setIsDeleteIssueDialogOpen(true);
  };

  const closeDeleteIssueDialog = () => {
    setIsDeleteIssueDialogOpen(false);
  };

  const navigateToEditorPage = ({ id, organizationId }) => {
    if (organizationId) {
      navigate(`/organizations/${organizationId}/issues/${id}`);
      return;
    }
    navigate(`/issues/${id}`);
  };

  const checkEditPermission = (issue) => {
    if (issue?.organizationId) {
      return editOrganizationIssuePermissions.has(
        issue.organizationId.toString()
      );
    }
    return hasUpdateLibraryIssuePermission;
  };

  const checkDeletePermission = (issue) => {
    if (issue?.organizationId) {
      return deleteOrganizationIssuePermissions.has(
        issue.organizationId.toString()
      );
    }
    return hasDeleteLibraryIssuePermission;
  };

  const CreateButton = () => {
    const hasCreatePermission =
      hasCreateLibraryIssuePermission || hasCreateOrganizationIssuePermissions;
    return (
      <RestrictedAccessTooltip hasPermission={hasCreatePermission}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigateToEditorPage({ id: "new" })}
          disabled={!hasCreatePermission}
        >
          CREATE
        </Button>
      </RestrictedAccessTooltip>
    );
  };

  return (
    <Page
      filters={
        <IssueFilter
          parentFilters={searchParams}
          disabledSearch={isFetching}
          onSearch={handleSearch}
        />
      }
    >
      <SubPage
        empty={!isFetching && !totalCount}
        emptyMessage="We couldn't find any issues matching your search criteria. Please adjust your filters or reach out to your Customer Success team for assistance."
        emptyChildren={
          <Stack direction="row" justifyContent="flex-end" width="100%">
            <CreateButton />
          </Stack>
        }
      >
        <PaginatedList
          loading={isFetching}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          handleSearch={handleSearch}
          toolbarComponent={<CreateButton />}
        >
          <Stack mt={2} mb={2} gap={2}>
            {(isFetching ? new Array(pageSize).fill(null) : issues).map(
              (issue, index) => (
                <IssueCard
                  showIssuePrompts
                  key={issue?.id ?? index}
                  issue={issue}
                  organization={organizationsDictionary[issue?.organizationId]}
                  isFetching={isFetching}
                  onEdit={
                    issue?.taggingLogic === TAGGING_LOGIC.PBC
                      ? (issue) => navigateToEditorPage(issue)
                      : null
                  }
                  onDelete={
                    issue?.taggingLogic === TAGGING_LOGIC.PBC
                      ? openDeleteIssueDialog
                      : null
                  }
                  hasEditPermission={checkEditPermission(issue)}
                  hasDeletePermission={checkDeletePermission(issue)}
                />
              )
            )}
          </Stack>
        </PaginatedList>
      </SubPage>
      <DeleteIssueDialog
        issue={selectedIssue}
        isOpen={isDeleteIssueDialogOpen}
        isLoading={isDeleting}
        onClose={closeDeleteIssueDialog}
        onDelete={handleDeleteIssue}
      />
    </Page>
  );
};

export default Issues;
