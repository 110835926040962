import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MaterialSymbol,
  TruncatedText,
  Typography,
} from "@tsc/component-library/lib/components";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import {
  STAKEHOLDER_SOURCE,
  STAKEHOLDER_SOURCE_PRIORITY,
} from "enums/stakeholderSource";
import lodash, { pick } from "lodash";

import {
  useUnlinkAttributeMutation,
  useUpsertGenfiveOverrideStakeholderAttributeMutation,
} from "api/stakeholders";
import StakeholderAvatar from "components/StakeholderAvatar/StakeholderAvatar";
import { selectPermissions } from "features/authentication/authenticationSlice";
import StakeholderAttributeEditorDialog from "features/stakeholders/StakeholderAttributesTable/StakeholderAttributeEditorDialog";
import StakeholderSourceAvatar from "features/stakeholders/StakeholderSourceAvatar/StakeholderSourceAvatar";
import { hasPermissionToken } from "utilities/data";
import { getStakeholderAttribute } from "utilities/stakeholder";

export const StakeholderAttributesTable = ({ stakeholder }) => {
  const [attributeToUnlink, setAttributeToUnlink] = useState(null);
  const userPermissions = useSelector(selectPermissions);
  const hasUpdatePermission = hasPermissionToken(
    userPermissions,
    OBJECT_TYPE.STAKEHOLDER,
    OBJECT_NAME.UPDATE
  );

  const [attributeToEdit, setAttributeToEdit] = useState(null);

  const [unlink] = useUnlinkAttributeMutation();

  const [
    upsertGenfiveOverrideStakeholderAttribute,
    { isLoading: isUpsertingGenfiveOverrideStakeholderAttribute },
  ] = useUpsertGenfiveOverrideStakeholderAttributeMutation();

  const handleEditClick = (stakeholderAttribute) => {
    setAttributeToEdit(stakeholderAttribute);
  };

  const handleSaveStakeholderAttribute = async (stakeholderAttribute) => {
    await upsertGenfiveOverrideStakeholderAttribute({
      stakeholderId: stakeholder.id,
      stakeholderAttribute: pick(stakeholderAttribute, [
        "name",
        "headline",
        "summary",
      ]),
    }).unwrap();
    setAttributeToEdit(null);
  };

  const handleUnlinkClick = (stakeholderAttribute) => {
    setAttributeToUnlink(stakeholderAttribute);
  };

  const handleClose = () => {
    setAttributeToUnlink(null);
  };

  const handleConfirmUnlink = async () => {
    await unlink({
      stakeholderId: stakeholder.id,
      stakeholderAttributeId: attributeToUnlink.id,
    }).unwrap();
    handleClose();
  };

  const stakeholderAttributes = lodash
    .chain(stakeholder?.stakeholderAttributes ?? [])
    .clone()
    .push({ id: null, source: STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE })
    .uniqBy("source")
    .sortBy(
      ({ source }) =>
        STAKEHOLDER_SOURCE_PRIORITY[source] ?? Number.MAX_SAFE_INTEGER
    )
    .value();

  stakeholderAttributes.unshift({
    id: -1,
    photo: getStakeholderAttribute(stakeholder, "photo"),
    name: getStakeholderAttribute(stakeholder, "name"),
    headline: getStakeholderAttribute(stakeholder, "headline"),
    summary: getStakeholderAttribute(stakeholder, "summary"),
  });

  return (
    <>
      <DataGridPro
        data-testid="stakeholderAttributesTable"
        columns={buildColumnDefinition(
          stakeholder,
          handleEditClick,
          handleUnlinkClick,
          hasUpdatePermission
        )}
        rows={stakeholderAttributes}
        getRowId={(row) => row.id ?? Number.MAX_SAFE_INTEGER}
        getRowClassName={(params) => (params.row.id === -1 ? "displayed" : "")}
        sx={{
          "& .displayed": {
            bgcolor: (theme) => `${theme.palette.action.focus} !important`,
          },
        }}
      />
      <StakeholderAttributeEditorDialog
        open={!!attributeToEdit}
        onClose={() => setAttributeToEdit(null)}
        onSubmit={handleSaveStakeholderAttribute}
        value={attributeToEdit}
        loading={isUpsertingGenfiveOverrideStakeholderAttribute}
      />
      <UnlinkAttributeDialog
        stakeholderAttribute={attributeToUnlink}
        open={attributeToUnlink !== null}
        onConfirm={handleConfirmUnlink}
        onClose={handleClose}
      />
    </>
  );
};

export default StakeholderAttributesTable;

const buildColumnDefinition = (
  stakeholder,
  onEdit,
  onUnlink,
  hasUpdatePermission
) => [
  {
    headerName: "Source",
    field: "source",
    type: "actions",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row: stakeholderAttribute }) =>
      stakeholderAttribute.id !== -1 && (
        <StakeholderSourceAvatar
          stakeholder={stakeholder}
          stakeholderAttribute={stakeholderAttribute}
        />
      ),
    sortable: false,
    minWidth: 100,
  },
  {
    headerName: "Photo",
    field: "photo",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row: stakeholderAttribute }) => (
      <StakeholderAvatar
        value={{
          ...stakeholder,
          stakeholderAttributes: [stakeholderAttribute],
        }}
      />
    ),
    sortable: false,
    minWidth: 50,
    width: 80,
  },
  {
    headerName: "Name",
    field: "name",
    renderCell: ({ value }) => <TruncatedText text={value} />,
    sortable: false,
    minWidth: 200,
  },
  {
    headerName: "Headline",
    field: "headline",
    renderCell: ({ value }) => <TruncatedText text={value} />,
    sortable: false,
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Summary",
    field: "summary",
    renderCell: ({ value }) => <TruncatedText text={value} />,
    sortable: false,
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "",
    field: "action",
    type: "actions",
    headerAlign: "center",
    align: "center",
    renderCell: ({ row: stakeholderAttribute }) => (
      <>
        {stakeholderAttribute.id > 0 &&
          stakeholderAttribute.source !==
            STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE && (
            <IconButton
              size="small"
              onClick={() => onUnlink(stakeholderAttribute)}
              disabled={!hasUpdatePermission}
            >
              <MaterialSymbol symbol="link_off" />
            </IconButton>
          )}
        {stakeholderAttribute.source ===
          STAKEHOLDER_SOURCE.GENFIVE_OVERRIDE && (
          <IconButton
            size="small"
            onClick={() => onEdit(stakeholderAttribute)}
            disabled={!hasUpdatePermission}
          >
            <MaterialSymbol symbol="edit" />
          </IconButton>
        )}
      </>
    ),
    sortable: false,
  },
];

const UnlinkAttributeDialog = ({
  stakeholderAttribute,
  onConfirm,
  onClose,
}) => {
  const [checked, setChecked] = useState(false);
  const open = stakeholderAttribute !== null;
  useEffect(() => setChecked(false), [open]);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Do you want to unlink this source?
        <IconButton
          size="small"
          sx={{ position: "absolute", right: 16, top: 16 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ width: 600, pb: 3 }}>
        <Typography>
          You are about to unlink this source
          <Typography fontWeight="600" component="span">
            {` [${stakeholderAttribute?.source ?? ""}:${
              stakeholderAttribute?.sourceId ?? ""
            }] `}
          </Typography>
          from the stakeholder.
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={() => setChecked((checked) => !checked)}
            />
          }
          label={
            <Typography mt={2} variant="body2" fontWeight={700} color="error">
              I understand that this source will not be linked to any
              stakeholders anymore and will not be visible.
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          CANCEL
        </Button>
        <Button variant="contained" onClick={onConfirm} disabled={!checked}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
};
