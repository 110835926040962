import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  Typography,
} from "@tsc/component-library/lib/components";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";

import TopNav from "components/TopNav/TopNav";
import { paths } from "configurations/router";
import {
  selectPermissions,
  selectPermissionsLoaded,
  signout,
} from "features/authentication/authenticationSlice";
import { useClassification } from "hooks/useClassification";
import { hasPermissionToken } from "utilities/data";

const GlobalLayout = () => {
  useClassification();
  const location = useLocation();
  const permissionsLoaded = useSelector(selectPermissionsLoaded);
  const userPermissions = useSelector(selectPermissions);
  const hasManagementPermission = hasPermissionToken(
    userPermissions,
    OBJECT_TYPE.MANAGEMENT,
    OBJECT_NAME.READ
  );

  useEffect(() => {
    const title = Object.values(paths).find(
      (path) => path.path === location.pathname
    )?.title;
    document.title = title ? `${title} - Genfive` : "Genfive";
  }, [location]);

  if (!permissionsLoaded) {
    return null;
  }

  if (!hasManagementPermission) {
    return <AccessDenied />;
  }

  return (
    <Box>
      <TopNav />
      <Box height={(theme) => theme.mixins.toolbar} />
      <Outlet />
    </Box>
  );
};

export default GlobalLayout;

const AccessDenied = () => {
  const dispatch = useDispatch();
  return (
    <Stack gap={2} alignItems="center">
      <Typography variant="h6">You are not allowed to see this page</Typography>
      <Box>
        <Button
          variant="contained"
          color="error"
          onClick={() => dispatch(signout())}
        >
          Log out
        </Button>
      </Box>
    </Stack>
  );
};
