import { useState } from "react";
import { useParams } from "react-router-dom";
import { components } from "@tsc/component-library/lib/commonTheme";
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
} from "@tsc/component-library/lib/components";
import { useDebounce, usePrevious } from "@uidotdev/usehooks";
import { isEmpty, keyBy, merge, uniq } from "lodash";

import { useGetStakeholdersQuery } from "api/stakeholders";
import StakeholderCardItem from "features/stakeholders/StakeholderCardItem/StakeholderCardItem";
import { getIdArray } from "utilities/array";
import { getStakeholderAttribute } from "utilities/stakeholder";

const StakeholderIdDropdown = ({
  autoFocus,
  value = [],
  onChange,
  disabled,
  sx,
  label,
  disableCloseOnSelect,
  getOptionDisabled,
  stakeholderType,
}) => {
  const { organizationId, projectId } = useParams();
  const [inputValue, setInputValue] = useState("");
  const debouncedSearchTerm = useDebounce(inputValue, 300);

  // when stakeholders are selected
  const { data: { data: stakeholderValues = [] } = {} } =
    useGetStakeholdersQuery(
      {
        organizationId,
        projectId,
        params: { ids: uniq(value).join(","), pageSize: value.length },
      },
      { skip: isEmpty(value) }
    );

  // when user types something
  const { data: stakeholderOptionsData, isFetching } = useGetStakeholdersQuery(
    {
      organizationId,
      projectId,
      params: {
        name: debouncedSearchTerm ?? "",
        type: stakeholderType ?? "",
      },
    },
    {
      skip:
        debouncedSearchTerm === false ||
        debouncedSearchTerm?.trim()?.length < 2,
    }
  );
  const previousStakeholderOptions = usePrevious(stakeholderOptionsData?.data);

  const stakeholderValuesMap = keyBy(stakeholderValues, "id");
  const stakeholderOptionsMap = keyBy(stakeholderOptionsData?.data, "id");
  const previousStakeholderOptionsMap = keyBy(previousStakeholderOptions, "id");
  const stakeholderMap = merge(
    stakeholderValuesMap,
    stakeholderOptionsMap,
    previousStakeholderOptionsMap
  );

  const handleInputChange = (event, value, reason) => {
    // To prevent the options from being reset when the user selects an option.
    if (event?.type !== "blur" && reason === "reset") {
      setInputValue(false);
      return;
    }
    setInputValue(value);
  };

  return (
    <Autocomplete
      sx={sx}
      multiple
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      size="small"
      fullWidth
      options={getIdArray(stakeholderOptionsData?.data ?? [])}
      filterOptions={(item) => item}
      getOptionKey={(id) => id}
      getOptionLabel={(id) => (
        <span className="notranslate">
          {getStakeholderAttribute(stakeholderMap[id], "name")}
        </span>
      )}
      getOptionDisabled={getOptionDisabled}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      onInputChange={handleInputChange}
      onClose={() => setInputValue("")}
      loading={isFetching}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={autoFocus}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...components.MuiTextField.defaultProps.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={label}
          placeholder={value?.length > 0 ? "" : "Select Stakeholders"}
        />
      )}
      renderOption={(props, id) => (
        <Box px={2} py={0.5} {...props}>
          <StakeholderCardItem
            stakeholder={stakeholderMap[id]}
            avatarSize="medium"
          />
        </Box>
      )}
    />
  );
};

export default StakeholderIdDropdown;
