import { useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CheckboxGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import { AI_MODEL } from "enums/aiModel";
import { FEATURE_FLAGS } from "enums/featureFlag";
import { MANAGEMENT_TYPES } from "enums/rbacManagementTypes";
import { STAKEHOLDER_TYPE } from "enums/stakeholderType";
import { omit } from "lodash";
import queryString from "query-string";

import { useGetFeatureFlagsQuery } from "api/featureFlag";
import { useGetOrganizationLogoQuery } from "api/organizations";
import RoleInput from "components/AccessControl/RoleInput";
import InputNumber from "components/Forms/InputNumber";
import SelectDropDown from "components/Select/SelectDropdown";
import OrganizationAvatar from "features/organizations/OrganizationDialog/OrganizationAvatar";
import StakeholderIdDropdown from "features/stakeholders/StakeholderIdDropdown/StakeholderIdDropdown";
import { checkPositiveInteger } from "utilities/number";

const DEFAULT_PROJECTS_LIMIT = 1;
const DEFAULT_TARGETED_SENTIMENT_LIMIT = 2;

const MAX_PROJECTS_LIMIT = 25;
const MIN_TARGET_SENTIMENT = 1;
const MAX_TARGET_SENTIMENT = 500;

const DEFAULT_ORGANIZATION = {
  domain: "",
  name: "",
  maxProjectCount: DEFAULT_PROJECTS_LIMIT,
  maxTargetedSentiment: DEFAULT_TARGETED_SENTIMENT_LIMIT,
  linkedStakeholderIds: [],
};

export const OrganizationDialog = ({
  inputOrganization = DEFAULT_ORGANIZATION,
  open,
  onClose,
  onSubmit,
}) => {
  const [organization, setOrganization] = useState(inputOrganization);
  const fileInputRef = useRef(null);
  const darkFileInputRef = useRef(null);
  const [avatar, setAvatar] = useState(null);
  const [darkAvatar, setDarkAvatar] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [darkImageFile, setDarkImageFile] = useState(null);
  const isValidProjectLimit =
    checkPositiveInteger(organization?.maxProjectCount) &&
    organization?.maxProjectCount <= MAX_PROJECTS_LIMIT;
  const isValidTargetedSentiment =
    organization?.maxTargetedSentiment >= MIN_TARGET_SENTIMENT &&
    organization?.maxTargetedSentiment <= MAX_TARGET_SENTIMENT;
  const { data: logoData, isError: isLogoError } = useGetOrganizationLogoQuery(
    {
      organizationId: organization.id,
      query: queryString.stringify({ theme: "light" }),
    },
    {
      skip: !organization?.id,
    }
  );

  const { data: darkLogoData, isError: isDarkLogoError } =
    useGetOrganizationLogoQuery(
      {
        organizationId: organization.id,
        query: queryString.stringify({ theme: "dark" }),
      },
      {
        skip: !organization?.id,
      }
    );

  const lightLogoUrl = logoData?.data?.lightLogoUrl;
  const darkLogoUrl = darkLogoData?.data?.darkLogoUrl;

  const {
    data: { data: featureFlags = [] } = {},
    isFetching: isFetchingFeatureFlags,
  } = useGetFeatureFlagsQuery();

  const featureFlagOptions = featureFlags.map(({ name }) => {
    return {
      label: FEATURE_FLAGS[name],
      value: name,
    };
  });

  useEffect(() => {
    if (open) {
      setOrganization({ ...DEFAULT_ORGANIZATION, ...inputOrganization });
    }
  }, [inputOrganization, open]);

  useEffect(() => {
    if (!open || !inputOrganization?.id) {
      setAvatar(null);
      setImageFile(null);
      setDarkAvatar(null);
      setDarkImageFile(null);
      return;
    }

    setAvatar(lightLogoUrl && !isLogoError ? lightLogoUrl : null);
    setDarkAvatar(darkLogoUrl && !isDarkLogoError ? darkLogoUrl : null);
  }, [
    open,
    inputOrganization,
    lightLogoUrl,
    isLogoError,
    darkLogoUrl,
    isDarkLogoError,
  ]);

  const handleSubmit = () => {
    onSubmit(
      omit(organization, ["permissions", "usedProjectCount"]),
      imageFile,
      darkImageFile
    );
  };

  const isCreatingNewOrg = !organization.id;
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleButtonClickDark = () => {
    darkFileInputRef.current.click();
  };

  const handleAvatarChange = (event, isDarkMode = false) => {
    const file = event.target?.files[0];
    if (!file) return;

    const fileReader = new FileReader();
    fileReader.onload = (e) =>
      isDarkMode ? setDarkAvatar(e.target.result) : setAvatar(e.target.result);
    fileReader.readAsDataURL(file);
    isDarkMode ? setDarkImageFile(file) : setImageFile(file);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        {isCreatingNewOrg ? "Create" : "Edit"} Organization
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <OrganizationAvatar
            name="Light mode logo"
            avatar={avatar}
            organizationName={organization?.name ?? ""}
            fileInputRef={fileInputRef}
            handleAvatarChange={handleAvatarChange}
            handleButtonClick={handleButtonClick}
          />
          <OrganizationAvatar
            name="Dark mode logo"
            avatar={darkAvatar}
            organizationName={organization?.name ?? ""}
            fileInputRef={darkFileInputRef}
            handleAvatarChange={handleAvatarChange}
            handleButtonClick={handleButtonClickDark}
            isDarkMode={true}
            disabled={!avatar}
          />
        </Box>

        <Box mt={2}>
          <Alert severity="info">
            <Typography variant="subtitle2">
              Logo requirements: JPEG/JPG or PNG format, 1-4 pixels
              outline/inset spacing. Provide two versions: one for light mode,
              one for dark mode.
            </Typography>
          </Alert>
        </Box>

        <Divider orientation="vertical" />
        <Box mt={2}>
          <Typography variant="subtitle2">
            You can change these details anytime in your organization setting
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography fontWeight={700}>
            <Typography component="span" color="error">
              *
            </Typography>
            Name
          </Typography>
        </Box>
        <Box mt={1}>
          <TextField
            autoFocus
            fullWidth
            required
            value={organization?.name ?? ""}
            onChange={(e) =>
              setOrganization((organization) => ({
                ...organization,
                name: e.target.value,
              }))
            }
            size="small"
            placeholder="Name"
            type="text"
            variant="outlined"
            disabled={isFetchingFeatureFlags}
          />
        </Box>
        <Box mt={2}>
          <Typography fontWeight={700}>
            <Typography component="span" color="error">
              *
            </Typography>
            Description
          </Typography>
        </Box>
        <Box mt={1}>
          <TextField
            fullWidth
            required
            multiline
            value={organization?.description ?? ""}
            onChange={(e) =>
              setOrganization((organization) => ({
                ...organization,
                description: e.target.value,
              }))
            }
            inputProps={{ maxLength: 500 }}
            size="small"
            placeholder="Description"
            type="text"
            rows={3}
            variant="outlined"
            FormHelperTextProps={{ sx: { textAlign: "right" } }}
            helperText={`${organization?.description?.length ?? 0} / 500`}
            disabled={isFetchingFeatureFlags}
          />
        </Box>

        <Typography mt={2} fontWeight={700}>
          My Organization
        </Typography>
        <Box mt={1}>
          <StakeholderIdDropdown
            disabled={isFetchingFeatureFlags}
            disableCloseOnSelect
            value={organization?.linkedStakeholderIds ?? []}
            onChange={(value) =>
              setOrganization((organization) => ({
                ...organization,
                linkedStakeholderIds: value,
              }))
            }
            stakeholderType={STAKEHOLDER_TYPE.ORGANIZATION}
          />
        </Box>

        <Box mt={2} display="flex" gap={2}>
          <Box flex={1} display="flex" flexDirection="column" gap={1}>
            <Typography fontWeight={700}>
              <Typography component="span" color="error">
                *
              </Typography>
              Limit of Projects
            </Typography>
            <InputNumber
              required
              decimal={0}
              value={organization?.maxProjectCount}
              onChange={(value) => {
                setOrganization((organization) => ({
                  ...organization,
                  maxProjectCount: value,
                }));
              }}
              error={!isValidProjectLimit}
              helperText={
                !isValidProjectLimit &&
                `Limit of Projects must be between 1 and ${MAX_PROJECTS_LIMIT}`
              }
              disabled={isFetchingFeatureFlags}
            />
          </Box>
          <Box flex={1} display="flex" flexDirection="column" gap={1}>
            <Typography fontWeight={700}>
              <Typography component="span" color="error">
                *
              </Typography>
              Limit of Targets for Sentiment
            </Typography>
            <InputNumber
              required
              decimal={0}
              value={organization?.maxTargetedSentiment}
              onChange={(value) => {
                setOrganization((organization) => ({
                  ...organization,
                  maxTargetedSentiment: value,
                }));
              }}
              error={!isValidTargetedSentiment}
              helperText={
                !isValidTargetedSentiment &&
                `Limit of Targets for Sentiment must be between ${MIN_TARGET_SENTIMENT} and ${MAX_TARGET_SENTIMENT}`
              }
              disabled={isFetchingFeatureFlags}
            />
          </Box>
        </Box>
        {!isCreatingNewOrg && (
          <>
            <Box mt={2}>
              <Typography>
                <b>Automatic Enrollment</b>
              </Typography>
            </Box>
            <Box mt={0.5}>
              <FormControlLabel
                control={
                  <Switch
                    checked={organization.autoEnroll}
                    onChange={(e) =>
                      setOrganization((organization) => ({
                        ...organization,
                        autoEnroll: e.target.checked,
                      }))
                    }
                    disabled={isFetchingFeatureFlags}
                  />
                }
                label="On"
              />
            </Box>
          </>
        )}
        {!isCreatingNewOrg && organization.autoEnroll && (
          <Stack mt={2} mb={1}>
            <TextField
              fullWidth
              required
              value={organization?.domain ?? ""}
              onChange={(e) =>
                setOrganization((organization) => ({
                  ...organization,
                  domain: e.target.value,
                }))
              }
              size="small"
              placeholder="Add domain*"
              type="text"
              variant="outlined"
              disabled={isFetchingFeatureFlags}
            />
            <RoleInput
              value={organization?.defaultRoleIds ?? []}
              onChange={(role) => {
                setOrganization((organization) => ({
                  ...organization,
                  defaultRoleIds: role,
                }));
              }}
              organizationId={organization.id}
              disabled={isFetchingFeatureFlags}
              managementType={MANAGEMENT_TYPES.CUSTOMER}
            />
          </Stack>
        )}
        <Box mt={2}>
          <Typography>
            <b>AI Model</b>
          </Typography>
        </Box>
        <Box mt={1}>
          <SelectDropDown
            value={organization?.aiModel}
            onChange={(value) =>
              setOrganization((organization) => ({
                ...organization,
                aiModel: value,
              }))
            }
            options={AI_MODEL}
          />
        </Box>
        <Box mt={2}>
          <Typography>
            <b>Feature Flags</b>
          </Typography>
        </Box>
        <Box mt={0.5}>
          <CheckboxGroup
            value={organization?.featureFlags || []}
            options={featureFlagOptions}
            onChange={(value) =>
              setOrganization((organization) => ({
                ...organization,
                featureFlags: value,
              }))
            }
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ m: 1, mx: 2 }}>
        <Button onClick={onClose} disabled={isFetchingFeatureFlags}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            !organization.name ||
            !organization.description ||
            !isValidProjectLimit ||
            !isValidTargetedSentiment ||
            !avatar ||
            !(
              (organization.autoEnroll && organization.domain) ||
              !organization.autoEnroll
            ) ||
            isFetchingFeatureFlags
          }
          onClick={handleSubmit}
        >
          {isCreatingNewOrg ? "Create" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
