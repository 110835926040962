import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  SvgIcon,
  Toolbar,
  Tooltip,
} from "@tsc/component-library/lib/components";
import { QuickAccessMenu } from "@tsc/component-library/lib/components/QuickAccessMenu";
import queryString from "query-string";

import ProfileMenu from "components/ProfileMenu/ProfileMenu";
import VerticalDivider from "components/VerticalDivider/VerticalDivider";
import { paths } from "configurations/router";

import { ReactComponent as TSCLogoText } from "../../assets/vectors/tsc_logo_text.svg";
import { selectUser } from "../../features/authentication/authenticationSlice";

export const topMenuHeight = "64px";

const TopNav = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const profileMenuOpen = Boolean(profileMenuAnchor);
  const handleOpenProfileMenu = (event) =>
    setProfileMenuAnchor(event.currentTarget);
  const handleCloseProfileMenu = () => setProfileMenuAnchor(null);
  const quickaccessOptions = [
    {
      title: "Issues",
      icon: PreviewOutlinedIcon,
      onClick: () => navigate("/issues"),
    },
    {
      title: "Organizations",
      icon: HomeOutlinedIcon,
      onClick: () => navigate("/organizations"),
    },
    {
      title: "RBAC",
      icon: LockPersonIcon,
      onClick: () => navigate(`/rbac`),
    },
    {
      title: "Stakeholders",
      icon: PersonOutlineIcon,
      onClick: () =>
        navigate(
          `/stakeholders?${queryString.stringify(
            paths.STAKEHOLDERS.defaultQueryParams
          )}`
        ),
    },
  ];
  if (!user) return null;
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "white",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar disableGutters sx={{ px: "8px", alignItems: "center" }}>
        <>
          <Box p={1}>
            <Link to="/">
              <SvgIcon
                component={TSCLogoText}
                viewBox="0 0 67 39"
                sx={{ width: "67px", height: "39px", ml: "6px", mt: "1px" }}
              />
            </Link>
          </Box>
        </>
        <Box minWidth={{ xs: "8px", sm: "56px" }} flexGrow={1} />
        <Tooltip title="Notifications">
          <IconButton>
            <Badge badgeContent={1} overlap="circular" color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <VerticalDivider />
        <QuickAccessMenu options={quickaccessOptions} />
        <VerticalDivider />
        <Tooltip title="Profile & Account Settings">
          <IconButton onClick={handleOpenProfileMenu}>
            <Avatar
              src={user.photoURL}
              imgProps={{ referrerPolicy: "no-referrer" }}
              sx={{ width: 32, height: 32 }}
            />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <ProfileMenu
        anchor={profileMenuAnchor}
        open={profileMenuOpen}
        onClose={handleCloseProfileMenu}
      />
    </AppBar>
  );
};

export default TopNav;
