import React from "react";
import { PaginatedList } from "@tsc/component-library/lib/components";
import { useSearchParams } from "@tsc/component-library/lib/hooks";
import { STAKEHOLDERS_SORT_BY } from "enums/stakeholderSortBy";
import { has } from "lodash";

import { useGetStakeholdersQuery } from "api/stakeholders";
import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import StakeholderFilter from "features/stakeholders/StakeholderFilter/StakeholderFilter";
import StakeholderTable from "features/stakeholders/StakeholderTable/StakeholderTable";
import { useScrollRef } from "hooks/useScrollRef";

const Stakeholders = () => {
  const [scrollRef, scrollToTop] = useScrollRef();
  const [searchParams, , setSearchParams] = useSearchParams();
  const {
    data: { data: stakeholders = [], totalCount = 0 } = {},
    isFetching,
    refetch,
  } = useGetStakeholdersQuery({ params: searchParams });
  const { page, pageSize } = searchParams;

  const handleSearch = async (newFilters) => {
    if (has(newFilters, "name")) {
      newFilters.name = newFilters.name
        ? newFilters.name.trim()
        : newFilters.name;
      newFilters.sortBy = newFilters.name?.trim()
        ? STAKEHOLDERS_SORT_BY.SEARCH_RELEVANCE
        : STAKEHOLDERS_SORT_BY.NAME;
    }
    await setSearchParams({ ...searchParams, ...newFilters });
    scrollToTop();
    refetch();
  };

  return (
    <Page ref={scrollRef}>
      <StakeholderFilter
        parentFilters={searchParams}
        onSearch={handleSearch}
        disabledSearch={isFetching}
      />
      <SubPage
        empty={!isFetching && !stakeholders.length}
        emptyMessage="We couldn't find any stakeholder matching your search criteria. Please
          adjust your filters or reach out to the Tech
          Team for assistance."
      >
        <PaginatedList
          loading={isFetching}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
          handleSearch={handleSearch}
        >
          <StakeholderTable
            isFetching={isFetching}
            value={stakeholders}
            loading={isFetching}
            rowHeight={72}
          />
        </PaginatedList>
      </SubPage>
    </Page>
  );
};

export default Stakeholders;
