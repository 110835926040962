import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Link, Typography } from "@tsc/component-library/lib/components";
import queryString from "query-string";

import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import { paths } from "configurations/router";

const LandingPage = () => {
  return (
    <Page hidePageHeader={true}>
      <SubPage empty={false}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Link component={RouterLink} to="/organizations">
              <Typography variant="h6">Organizations</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link
              component={RouterLink}
              to={`/issues?${queryString.stringify(
                paths.ISSUES.defaultQueryParams
              )}`}
            >
              <Typography variant="h6">Issues</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link component={RouterLink} to="/rbac">
              <Typography variant="h6">Access Control</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link
              component={RouterLink}
              to={`/stakeholders?${queryString.stringify(
                paths.STAKEHOLDERS.defaultQueryParams
              )}`}
            >
              <Typography variant="h6">Stakeholders</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link component={RouterLink} to={`/generate`}>
              <Typography variant="h6">Profile Generation</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link component={RouterLink} to={`/superprompts`}>
              <Typography variant="h6">Superprompts</Typography>
            </Link>
          </Grid>
        </Grid>
      </SubPage>
    </Page>
  );
};

export default LandingPage;
