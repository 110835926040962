import { lowerCase, startCase } from "lodash";

/**
 * Return the lowercase transformation of a string
 * @param {string} value
 * @returns lowercase string or the original value if it is not a string
 */
export function getLowerCase(value) {
  if (typeof value === "string") {
    return value.toLowerCase().trim();
  }
  return value;
}

/**
 * Return the title case transformation of a string
 * @param {string} value
 * @returns string value of the title case transformation.
 */
export function getTitleCase(value) {
  return startCase(lowerCase(value));
}
