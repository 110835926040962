import React, { useState } from "react";
import { components } from "@tsc/component-library/lib/commonTheme";
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
} from "@tsc/component-library/lib/components";
import { useDebounce } from "@uidotdev/usehooks";
import { STAKEHOLDERS_SORT_BY } from "enums/stakeholderSortBy";

import { useGetStakeholdersQuery } from "api/stakeholders";
import Stakeholder from "features/stakeholders/Stakeholder/Stakeholder";
import { getStakeholderAttribute } from "utilities/stakeholder";

const StakeholderDropdown = ({
  autoFocus,
  value = [],
  onChange,
  disabled,
  sx,
  onEnter = () => {},
  getOptionDisabled,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(inputValue, 300);

  const { data: { data: stakeholders = [] } = {}, isFetching } =
    useGetStakeholdersQuery(
      {
        params: {
          name: debouncedSearchTerm,
          sortBy: debouncedSearchTerm
            ? STAKEHOLDERS_SORT_BY.TERM_RELEVANCE
            : STAKEHOLDERS_SORT_BY.NAME,
        },
      },
      { skip: debouncedSearchTerm?.trim()?.length < 2 }
    );

  return (
    <Autocomplete
      sx={sx}
      size="small"
      fullWidth
      multiple
      disabled={disabled}
      loading={isFetching}
      value={value}
      options={stakeholders}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => getStakeholderAttribute(option, "name")}
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, newValue) => onChange(newValue)}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Stakeholders"
          placeholder={value?.length > 0 ? "" : "Select Stakeholders"}
          autoFocus={autoFocus}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...components.MuiTextField.defaultProps.InputLabelProps,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isOpen) {
              onEnter(e);
            }
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box px={2} py={0.5} {...props}>
          <Stakeholder value={option} />
        </Box>
      )}
    />
  );
};

export default StakeholderDropdown;
