import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Divider,
  SkeletonLoader,
  Stack,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@tsc/component-library/lib/components";
import { CONNECTION_TYPE } from "enums/connectionType";
import { OBJECT_NAME } from "enums/objectName";
import { OBJECT_TYPE } from "enums/objectType";
import { STAKEHOLDER_CLASS } from "enums/stakeholderClass";
import { STAKEHOLDER_TYPE } from "enums/stakeholderType";
import { upperFirst } from "lodash";

import { useGetConnectionsQuery } from "api/connections";
import {
  useGetStakeholderByIdQuery,
  usePatchStakeholderMutation,
} from "api/stakeholders";
import StakeholderCuratedIcon from "assets/icons/StakeholderCuratedIcon";
import Page, { INTERNAL_PAGE_PADDING } from "components/Layout/Page";
import StakeholderAvatar from "components/StakeholderAvatar/StakeholderAvatar";
import { selectPermissions } from "features/authentication/authenticationSlice";
import CareerEducationSection from "features/stakeholders/CareerEducationSection/CareerEducationSection";
import ConnectionSection from "features/stakeholders/ConnectionSection/ConnectionSection";
import StakeholderAttributesTable from "features/stakeholders/StakeholderAttributesTable/StakeholderAttributesTable";
import StakeholderSourceAvatarGroup from "features/stakeholders/StakeholderSourceAvatarGroup/StakeholderSourceAvatarGroup";
import StakeholderVisibilityChip from "features/stakeholders/StakeholderVisibilityChip/StakeholderVisibilityChip";
import { hasPermissionToken } from "utilities/data";
import { getStakeholderAttribute } from "utilities/stakeholder";

const STAKEHOLDER_DETAILS_TABS = {
  INSIGHTS: 0,
  ABOUT: 1,
  ACTIVITY: 2,
  CONNECTIONS: 3,
  MANAGE: 4,
};

const StakeholderDetails = () => {
  const { organizationId, projectId, stakeholderId } = useParams();

  const {
    data: { data: stakeholder } = {},
    isFetching,
    error,
  } = useGetStakeholderByIdQuery({
    organizationId,
    projectId,
    stakeholderId,
  });

  const defaultTab = STAKEHOLDER_DETAILS_TABS.MANAGE;
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  return (
    <Page errorCode={error?.status}>
      <Stack m={-INTERNAL_PAGE_PADDING} flex={1}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          px={INTERNAL_PAGE_PADDING}
          justifyContent="space-between"
        >
          <StakeholderDetailsHeader
            stakeholder={stakeholder}
            isFetching={isFetching}
          />
        </Stack>
        <Box position="relative">
          <Tabs
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
            sx={{ px: 2 }}
          >
            <Tab
              value={STAKEHOLDER_DETAILS_TABS.MANAGE}
              label="Manage"
              data-testid="stakeholderDetailsManageTab"
              disabled={isFetching}
            />
            <Tab
              value={STAKEHOLDER_DETAILS_TABS.ABOUT}
              label="About"
              data-testid="stakeholderDetailsAboutTab"
              disabled={isFetching}
            />
          </Tabs>
          <Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              borderBottomWidth: 1,
              borderBottomColor: "divider",
              borderBottomStyle: "solid",
            }}
          />
        </Box>

        {selectedTab === STAKEHOLDER_DETAILS_TABS.MANAGE && (
          <StakeholderDetailsManageTab
            stakeholder={stakeholder}
            isFetching={isFetching}
          />
        )}

        {selectedTab === STAKEHOLDER_DETAILS_TABS.ABOUT && (
          <StakeholderDetailsAboutTab
            stakeholder={stakeholder}
            isFetching={isFetching}
          />
        )}
      </Stack>
    </Page>
  );
};

const StakeholderDetailsHeader = ({ stakeholder, isFetching }) => {
  const name = getStakeholderAttribute(stakeholder, "name");
  const headline = getStakeholderAttribute(stakeholder, "headline");
  const userPermissions = useSelector(selectPermissions);
  const hasUpdatePermission = hasPermissionToken(
    userPermissions,
    OBJECT_TYPE.STAKEHOLDER,
    OBJECT_NAME.UPDATE
  );

  const [patchStakeholder, { isLoading }] = usePatchStakeholderMutation();
  const handleToggleVisibility = () => {
    patchStakeholder({
      stakeholderId: stakeholder.id,
      stakeholder: { id: stakeholder.id, isVisible: !stakeholder.isVisible },
    });
  };

  return (
    <Stack direction="row" gap={2} m={2} flexGrow={1}>
      <StakeholderAvatar
        size="extra-large"
        value={stakeholder}
        loading={isFetching}
      />
      <Stack flex={1} flexGrow={1}>
        <Typography
          variant="h6"
          sx={{ minWidth: "15%" }}
          className="notranslate"
        >
          <SkeletonLoader loading={isFetching}>
            {name}
            {stakeholder?.class === STAKEHOLDER_CLASS.MANUAL && (
              <Tooltip title="Curated by TSC analyst">
                <StakeholderCuratedIcon sx={{ ml: 1, verticalAlign: "sub" }} />
              </Tooltip>
            )}
          </SkeletonLoader>
        </Typography>
        {(isFetching || headline) && (
          <Typography variant="subtitle2" mb={1}>
            <SkeletonLoader loading={isFetching} sx={{ minWidth: "30%" }}>
              {upperFirst(headline)}&nbsp;
            </SkeletonLoader>
          </Typography>
        )}
        <Stack direction="row" gap={0.5}>
          <StakeholderSourceAvatarGroup
            stakeholder={stakeholder}
            loading={isFetching}
          />
        </Stack>
      </Stack>
      <Stack direction="row" gap={2}>
        <Box>
          <Switch
            data-testid="stakeholderVisibilityToggle"
            checked={stakeholder?.isVisible ?? false}
            disabled={!hasUpdatePermission || isLoading}
            onClick={handleToggleVisibility}
          />
          <StakeholderVisibilityChip stakeholder={stakeholder} />
        </Box>
      </Stack>
    </Stack>
  );
};

const StakeholderDetailsManageTab = ({ stakeholder }) => {
  return (
    <Box p={2}>
      <StakeholderAttributesTable stakeholder={stakeholder} />
    </Box>
  );
};

const StakeholderDetailsAboutTab = ({ stakeholder }) => {
  const { organizationId, projectId, stakeholderId } = useParams();

  const params = {
    types: [CONNECTION_TYPE.CAREER, CONNECTION_TYPE.EDUCATION],
    sourceNodeId: stakeholder?.id,
    sourceNodeType: STAKEHOLDER_TYPE.PERSON,
    pageSize: 1000,
    page: 1,
  };
  const {
    data: { data: connections = [] } = {},
    isFetching: isFetchingCareersEducations,
  } = useGetConnectionsQuery(
    { organizationId, projectId, params },
    { skip: stakeholder?.type !== STAKEHOLDER_TYPE.PERSON }
  );
  const careers = connections.filter(
    (careerEducation) => careerEducation.type === CONNECTION_TYPE.CAREER
  );
  const educations = connections.filter(
    (careerEducation) => careerEducation.type === CONNECTION_TYPE.EDUCATION
  );

  const summary = getStakeholderAttribute(stakeholder, "summary");

  return (
    <Stack direction={{ xs: "column", lg: "row" }} flex={1} p={2}>
      <Stack minWidth={0} flex={{ lg: 1 }} divider={<Divider />}>
        <Stack gap={2} m={2}>
          <Typography variant="subtitle2">
            Key Points
            <InfoOutlinedIcon
              fontSize="inherit"
              color="inherit"
              sx={{ ml: "8px", mb: "-2px" }}
            />
          </Typography>
          <Typography
            variant="body1"
            color="text.primary"
            whiteSpace="pre-wrap"
            sx={{ wordBreak: "break-word" }}
          >
            {upperFirst(summary)}
          </Typography>
        </Stack>
        {stakeholder?.type === STAKEHOLDER_TYPE.PERSON && (
          <CareerEducationSection
            title="Career"
            data={careers}
            isFetching={isFetchingCareersEducations}
            stakeholderId={stakeholderId}
          />
        )}
        {stakeholder?.type === STAKEHOLDER_TYPE.PERSON && (
          <CareerEducationSection
            title="Education"
            data={educations}
            isFetching={isFetchingCareersEducations}
            stakeholderId={stakeholderId}
          />
        )}
        {stakeholder?.type === STAKEHOLDER_TYPE.ORGANIZATION && (
          <ConnectionSection
            title="Roles"
            type={CONNECTION_TYPE.CAREER}
            sourceNodeType={STAKEHOLDER_TYPE.ORGANIZATION}
            targetNodeType={STAKEHOLDER_TYPE.PERSON}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default StakeholderDetails;
