import { useState } from "react";
import { green, grey } from "@mui/material/colors";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import { MANAGEMENT_TYPES } from "enums/rbacManagementTypes";

import { transformUserRoles } from "utilities/user";

import { AddUserDialog } from "./AddUserDialog";

const ResourceUsers = ({
  users,
  roles,
  onCreateUser,
  onUpdateUser,
  organizationId,
  objectType,
  managementType,
}) => {
  const [open, setOpen] = useState(false);
  const [userFilter, setUserFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");

  const isCustomer = managementType === MANAGEMENT_TYPES.CUSTOMER;

  const mustDisplay = (user, managementType) => {
    if (managementType === MANAGEMENT_TYPES.CUSTOMER) {
      return true;
    } else {
      const isGlobalSelected =
        managementType === MANAGEMENT_TYPES.GLOBAL_MANAGEMENT;
      return user.userManagementRoles.some(
        (role) => role.managementRole.global === isGlobalSelected
      );
    }
  };
  const canDisplay = (user, managementType) =>
    managementType !== MANAGEMENT_TYPES.CUSTOMER &&
    user.email.endsWith("@tsc.ai");
  const eligibleUsers = users
    .filter((user) => !["*", "server"].includes(user.email))
    .filter(
      (user) =>
        mustDisplay(user, managementType) || canDisplay(user, managementType)
    );

  const hasRole = (user, roleId) => {
    if (isCustomer) {
      return user?.userRoles?.some((r) => r.roleId === roleId);
    } else {
      return user?.userManagementRoles?.some(
        (r) => r.managementRoleId === roleId
      );
    }
  };

  const getColumnDefinition = (roles) => [
    {
      headerName: "User",
      field: "meta",
      minWidth: 250,
      sortable: false,
    },
    ...roles.map((role) => ({
      headerName: `${role.name} (${
        eligibleUsers?.filter((u) => hasRole(u, role.id)).length
      })`,
      field: role.id.toString(),
      renderCell: ({ row, value }) => {
        const checked = hasRole(row, role.id);
        return (
          <Checkbox
            checked={checked}
            sx={{
              color: grey[300],
              "&.Mui-checked": {
                color: green[500],
              },
            }}
            onClick={() => onUpdateUser({ ...row, [role.id]: !checked })}
          />
        );
      },
      sortable: false,
      headerAlign: "center",
      align: "center",
      minWidth: 200,
    })),
  ];

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2} alignItems="baseline" mt={1}>
        <Typography variant="subtitle1" fontWeight="500">
          Users
        </Typography>
        <TextField
          value={userFilter}
          label="Filter Users"
          variant="outlined"
          size="small"
          onChange={(e) => setUserFilter(e.target.value)}
        />
        <TextField
          value={roleFilter}
          label="Filter Roles"
          variant="outlined"
          size="small"
          onChange={(e) => setRoleFilter(e.target.value)}
        />
        <Box flexGrow={1} />
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          ADD
        </Button>
        <AddUserDialog
          open={open}
          setOpen={setOpen}
          onSubmit={onCreateUser}
          organizationId={organizationId}
          objectType={objectType}
          managementType={managementType}
        />
      </Stack>
      <DataGridPro
        autoHeight
        style={{ border: "none" }}
        columns={getColumnDefinition(
          roles.filter(({ name }) =>
            name.toLowerCase().includes(roleFilter.toLowerCase())
          )
        )}
        rows={getUserDefinitions(
          eligibleUsers.filter(({ displayName, email }) =>
            `${displayName} ${email}`
              .toLowerCase()
              .includes(userFilter.toLowerCase())
          ),
          isCustomer
        )}
        filterMode="server"
        sortingMode="server"
        rowsLoadingMode="server"
        rowSelection={false}
        disableColumnMenu
        hideFooter
        disableColumnResize
        sortingOrder={["asc", "desc"]}
        sx={{
          "& .MuiDataGrid-columnHeaderTitleContainerContent": {
            whiteSpace: "normal !important",
            wordWrap: "break-word !important",
            lineHeight: "normal",
          },
          "& .MuiDataGrid-cell": {
            border: "1px solid #f0f0f0",
          },
          "& .MuiDataGrid-columnHeader": {
            display: "block",
            backgroundColor: "#fafafa",
            border: "1px solid #f0f0f0",
          },
        }}
      />
    </Stack>
  );
};

export default ResourceUsers;

function getUserDefinitions(users, isCustomer) {
  return users.map((user) => transformUserRoles(user, isCustomer));
}
