import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Editor,
  IconButton,
  LabeledComponent,
  TextField,
} from "@tsc/component-library/lib/components";
import { EDITOR_FORMAT } from "@tsc/component-library/lib/components/Editor/Editor";
import { useInputs } from "@tsc/component-library/lib/hooks";

const DEFAULT_VALUE = {
  name: "",
  headline: "",
  summary: "",
};
const EDITOR_TOOLBAR_OPTIONS = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
];

const StakeholderAttributeEditorDialog = ({
  open,
  onClose,
  onSubmit,
  value,
  loading,
}) => {
  const [inputs, setInput, setInputs] = useInputs(DEFAULT_VALUE);

  const handleClose = () => {
    if (!onClose || loading) {
      return;
    }
    onClose();
  };

  const handleSubmit = () => {
    if (!onSubmit || loading) {
      return;
    }
    onSubmit(inputs);
  };

  useEffect(() => {
    if (open) {
      setInputs({ ...DEFAULT_VALUE, ...value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Edit Stakeholder Attribute
        <IconButton
          size="small"
          sx={{ position: "absolute", right: 16, top: 16 }}
          onClick={onClose}
          disabled={loading}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <LabeledComponent title="Name" vertical removeMargin>
          <TextField
            autoFocus
            fullWidth
            inputProps={{ maxLength: 4096 }}
            value={inputs.name}
            onChange={(event) => setInput("name", event.target.value)}
            disabled={loading}
          />
        </LabeledComponent>

        <LabeledComponent title="Headline" vertical removeMargin mt={2}>
          <TextField
            fullWidth
            inputProps={{ maxLength: 256 }}
            value={inputs.headline}
            onChange={(event) => setInput("headline", event.target.value)}
            disabled={loading}
          />
        </LabeledComponent>

        <LabeledComponent title="Summary" vertical removeMargin mt={2}>
          <Editor
            format={EDITOR_FORMAT.MARKDOWN}
            disabled={loading}
            value={inputs.summary}
            onChange={(value) => setInput("summary", value)}
            characterLimit={4096}
            sx={{ height: 150 }}
            toolbarOptions={EDITOR_TOOLBAR_OPTIONS}
          />
        </LabeledComponent>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={
            loading ? <CircularProgress size={14} color="inherit" /> : null
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StakeholderAttributeEditorDialog;
